<template>
  <div class="form-paypal">
    <div class="form-paypal__row">
      <VSelect
        label="Payment method *"
        size="sm"
        get-type="object"
        :options="receptionMethodOptions"
        :value="form.paymentMethod"
        @input="form.paymentMethod = $event.id"
      />

      <FieldText
        v-if="form.paymentMethod === 'email'"
        label-text="Email address *"
        v-model="form.emailAddress"
      />

      <FieldText
        v-if="form.paymentMethod === 'pay_pal_account'"
        label-text="PayPal Account *"
        v-model="form.paypalAccount"
      />

      <FieldText
        v-if="form.paymentMethod === 'phone'"
        label-text="Phome *"
        v-model="form.phone"
      />

    </div>
  </div>
</template>

<script>
import FieldText from '@/js/components/common/form/FieldText';
import VSelect from '@/js/components/common/VSelect';

export default {
  name: "FormPayPal",
  components: { FieldText, VSelect },
  props: {
    defaultForm: Object,
  },
  data() {
    return {
      form: this.defaultForm,
      receptionMethodOptions: [
        {
          id: 'email',
          label: 'Email'
        },
        {
          id: 'pay_pal_account',
          label: 'PayPal Account'
        },
        {
          id: 'phone',
          label: 'Phone'
        },
      ]
    }
  }
};
</script>

<style lang="scss" scoped>
.form-paypal {
  &__row {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 43px;
  }
}
</style>