<template>
  <div class="form-wire">
    <div class="form-wire__row">
      <FieldText
        label-text="Bank name *"
        :validation="$v.form.bankName.$error"
        v-model="form.bankName"
      />
      <FieldText
        label-text="Bank address *"
        :validation="$v.form.bankAddress.$error"
        v-model="form.bankAddress"
      />
    </div>
    <div class="form-wire__row">
      <FieldText
        label-text="Account name *"
        :validation="$v.form.accountName.$error"
        v-model="form.accountName"
      />
      <FieldText
        label-text="Account number *"
        :validation="$v.form.accountNumber.$error"
        v-model="form.accountNumber"
      />
    </div>
    <div class="form-wire__row">
      <FieldText
        label-text="Routing number / IBAN *"
        :validation="$v.form.routingNumber.$error"
        v-model="form.routingNumber"
      />
      <FieldText
        label-text="Swift code *"
        :validation="$v.form.swiftCode.$error"
        v-model="form.swiftCode"
      />

    </div>
  </div>
</template>

<script>
import FieldText from '@/js/components/common/form/FieldText';
import { required } from 'vuelidate/lib/validators';

export default {
  name: "FormWire",
  components: { FieldText },
  props: {
    defaultForm: Object,
  },
  data() {
    return {
      form: this.defaultForm,
    };
  },
  validations: {
    form: {
      bankName: {
        required,
      },
      bankAddress: {
        required,
      },
      accountName: {
        required,
      },
      accountNumber: {
        required,
      },
      routingNumber: {
        required,
      },
      swiftCode: {
        required,
      },
    }
  },
  methods: {
    onValidationForm() {
      this.$v.$touch();
      if (this.$v.$invalid) return;
      this.$emit('setForm', this.form);
    }
  }
};
</script>

<style lang="scss" scoped>
.form-wire {
  &__row {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 43px;
    margin-bottom: 32px;
  }
}
</style>