<template>
  <div class="v-hr"></div>
</template>

<script>
export default {
  name: "Vhr"
};
</script>

<style lang="scss" scoped>
.v-hr {
  width: 100%;
  height: 1px;
  background-color: var(--grey-light-1);
}
</style>