<template>
  <div>
    <VBox class="payment-details__form">
      <VOverlay v-if="_updatingStatus === requestStatus.PENDING">
        <VLoader />
      </VOverlay>

      <div class="title-page mb-4">Payment method details</div>
      <form @submit.prevent="onSaveForm">
        <div class="payment-details__row">
          <FieldText
            label-text="Payment method name *"
            :validation="$v.form.methodName.$error"
            v-model="form.methodName"
          />
          <VSelect
            label="Payment method"
            size="sm"
            get-type="object"
            :options="methodTypeOptions"
            :value="form.methodType"
            :error="$v.form.methodType.$error"
            @input="form.methodType = $event.id"
          />
        </div>
        <Vhr class="my-3" />
        <div class="payment-details__methods">
          <FormWire
            v-if="form.methodType === 0"
            :default-form="form.wire"
            @setForm="form.wire = { ...form.wire, ...$event}"
            ref="wireForm"
          />
          <FormPayPal
            v-if="form.methodType === 1"
            :default-form="form.paypal"
          />
          <div
            v-if="form.methodType === 0 || form.methodType === 1"
            class="d-flex align-items-center"
          >
            <VCheckbox
              :disabled="_defaultPaymentMethodIsEmpty"
              :checked="form.isDefault"
              @change="onSetAsDefault"
            />
            <div class="ml-1">Set as default</div>
          </div>
        </div>

        <div class="d-flex justify-content-end mt-4">
          <VButton
            type="orange-outline"
            class="btn-xl mr-2"
            @click="$router.back()"
          >
            Cancel
          </VButton>
          <VButton
            type="orange"
            class="btn-xl"
            type-btn="submit"
          >
            Save
          </VButton>
        </div>
      </form>
    </VBox>

    <PopupConfirm
      v-if="popupConfirmIsVisible"
      @cancel="popupConfirmIsVisible = false"
      @confirm="onChangePaymentMethod"
    >
      <template #title>
        Changing default payment method
      </template>
      <template #body>
        <div class="mb-2">Your current payment method is: <strong>'{{ _defaultPaymentMethod.name }}'</strong></div>
        <div class="mb-2">Do you want to set <strong>'{{ form.methodName }}'</strong> as default?</div>
        <div class="color-grey-main">Note: you can have only one active payment method</div>
      </template>
    </PopupConfirm>
  </div>
</template>

<script>
import FieldText from '@/js/components/common/form/FieldText';
import VCheckbox from '@/js/components/common/VCheckbox';
import Vhr from '@/js/components/common/Vhr';
import VSelect from '@/js/components/common/VSelect';
import VBox from '@/js/components/common/VBox';
import PopupConfirm from '@/js/components/modal/PopupConfirm';
import FormPayPal from '@/js/components/pages/payments/HandlePaymentDetails/FormPayPal';
import FormWire from '@/js/components/pages/payments/HandlePaymentDetails/FormWire';
import { required } from 'vuelidate/lib/validators';
import { requestStatus, toasted } from '@/js/utils/constants';

export default {
  name: "HandlePaymentDetails",
  components: {
    VCheckbox,
    PopupConfirm,
    FormPayPal,
    FormWire,
    Vhr,
    VSelect,
    FieldText,
    VBox
  },
  props: {
    paymentMethodId: Number,
    paymentDetails: Object,
  },
  data() {
    return {
      requestStatus,
      form: {
        methodName: '',
        methodType: '',
        isDefault: false,
        overrideDefaultPaymentMethod: false,
        wire: {
          bankName: '',
          bankAddress: '',
          accountName: '',
          accountNumber: '',
          routingNumber: '',
          swiftCode: '',
        },
        paypal: {
          paymentMethod: '',
          emailAddress: '',
          paypalAccount: '',
          phone: '',
        },
      },
      methodTypeOptions: [
        {
          id: 0,
          label: 'Wire'
        },
        // { // not used now
        //   id: 1,
        //   label: 'PayPal'
        // },
      ],
      popupConfirmIsVisible: false,
    }
  },
  validations: {
    form: {
      methodName: {
        required,
      },
      methodType: {
        required,
      },
    }
  },
  computed: {
    _defaultPaymentMethod() {
      return this.$store.state.paymentMethods.defaultPaymentMethod;
    },
    _defaultPaymentMethodIsEmpty() {
      return !Object.keys(this._defaultPaymentMethod).length;
    },
    _userProfile() {
      return this.$store.getters['auth/userProfile'];
    },
    _updatingStatus() {
      return this.$store.state.paymentMethodsDetails.updatingStatus;
    },
  },
  mounted() {
    if (this._defaultPaymentMethodIsEmpty) this.form.isDefault = true;
    if (this.paymentMethodId) this.setForm();
  },
  methods: {
    setForm() {
      const { name, method_id, is_default, additional_data } = this.paymentDetails;

      this.form = {
        ...this.form,
        methodName: name,
        methodType: method_id,
        isDefault: is_default,
        wire: {
          bankName: additional_data.bank_name,
          bankAddress: additional_data.bank_address,
          accountName: additional_data.account_name,
          accountNumber: additional_data.account_number,
          routingNumber: additional_data.routing_number,
          swiftCode: additional_data.swift_code,
        }
      };
    },
    onSetAsDefault(checked) {
      if (checked) {
        this.popupConfirmIsVisible = true;
      } else {
        this.form.isDefault = checked;
        this.form.overrideDefaultPaymentMethod = false;
      }
    },
    onChangePaymentMethod() {
      this.form.isDefault = true;
      this.form.overrideDefaultPaymentMethod = true;
      this.popupConfirmIsVisible = false;
    },
    async onSaveForm() {
      this.$v.$touch();
      if (this.$v.$invalid) return;

      if (this.form.methodType === 0) {
        this.$refs.wireForm.onValidationForm();
        if (this.$refs.wireForm.$v.$invalid) return;
      }

      const {
        methodName,
        methodType,
        isDefault,
        overrideDefaultPaymentMethod,
        wire,
      } = this.form;

      const formData = {
        user_id: this._userProfile.id,
        name: methodName,
        method_id: methodType, // wire - 0 | paypal - 1
        is_default: isDefault,
        override_default_payment_method: overrideDefaultPaymentMethod,
        additional_data: {
          bank_name: wire.bankName,
          bank_address: wire.bankAddress,
          account_name: wire.accountName,
          account_number: wire.accountNumber,
          routing_number: wire.routingNumber,
          swift_code: wire.swiftCode,
          // paypal_reception_method: "phone",
          // reception_identifier: "13613393936",
        },
      };

      const typeRoute = this.paymentMethodId ? 'editPaymentMethod' : 'createPaymentMethod';

      try {
        await this.$store.dispatch(`paymentMethodsDetails/${typeRoute}`, formData);
        this.$toasted.success(toasted.text.success);
        this.$router.push('/payments');
      } catch (e) {
        console.log('=> ERR', e.response.data);
        const { message } = e.response.data;
        const msg = message ? message : toasted.text.error;
        this.$toasted.error(msg);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.payment-details {
  &__form {
    position: relative;
    width: 100%;
    max-width: 574px;
    margin: 75px auto 0;
  }

  &__row {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 43px;
  }

  &__methods {
    min-height: 330px;
  }
}
</style>