<template>
  <h2 class="title-page">
    <router-link to="/payments" class="payment-details__link">
      Payments
    </router-link>
    <icon-arrow class="payment-details__arrow" color="#1E70BB" />
    {{ type }} payment method
  </h2>
</template>

<script>
import IconArrow from "@/js/components/icons/IconArrow";

export default {
  name: "PaymentTitle",
  components: {
    IconArrow
  },
  props: {
    type: String,
  }
};
</script>

<style lang="scss" scoped>
.payment-details {
  &__link {
    text-decoration: none;
    color: var(--blue-main);
  }

  &__arrow {
    margin: 0 4px;
  }
}
</style>